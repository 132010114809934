// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const MUTATION = gql`
  mutation DossierTypeListMultiColumnInlineEditFormByBlockId(
    $blockId: ID!,
    $columnName: String!,
    $data: JSON
  ) {
    form: dossierTypeListMultiColumnInlineEditFormByBlockId(
      blockId: $blockId,
      columnName: $columnName,
      data: $data
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export default MUTATION;
